import { BryntumCalendar } from '@bryntum/calendar-react';
import { useRef } from 'react';
import { Grid } from '@mui/material';
import { DateHelper, EventModel, EventStore, LocaleManager, Model, Popup, ResourceModel, ResourceStore, StringHelper } from '@bryntum/calendar';
import '@bryntum/calendar/locales/calendar.locale.Hu.js';
import { busStore } from '../Bus/BusSelector';
import { buszrentEmployeeStore, vezenyloStore } from '../Employee/EmployeeSelector';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { Role } from '../../@types/auth';
import { ResourceID } from '../../@types/event';
import { SecuredLayout } from '../Layout/SecuredLayout';
import { ConductorType } from '../../@types/employee';
import { EmployeeModel } from '../Employee/EmployeeModel';
import writeXlsxFile from 'write-excel-file';

LocaleManager.applyLocale('Hu');

const CALENDAR_WEBSOCKET_ENDPOINT = (window as any).REACT_APP_CALENDAR_WEBSOCKET_ENDPOINT || '';

class MyXlsProvider {
    static write({ filename, columns, rows }: any) {
        columns.forEach((col: any) => delete col.type);
        rows.forEach((row: any) => row.forEach((cell: any) => (cell.type = String)));

        writeXlsxFile([columns, ...rows], {
            columns: columns.map((col: any) => ({ ...col, width: Math.round(col.width / 10) })),
            fileName: filename,
            dateFormat: 'yyyy-mm-dd hh:mm',
        });
    }
}

export default function Calendar() {
    const { user, signOut } = useAuth();
    const { showResponseError, showError, showSuccess } = useProvideSnackBar();
    const calendar = useRef<BryntumCalendar>(null);

    if (!user) {
        return null;
    }

    const today = new Date();
    let socket: WebSocket;

    function connectWebSocket() {
        socket = new WebSocket(`${CALENDAR_WEBSOCKET_ENDPOINT}/api/event/ws`);
        socket.onopen = function () {
            console.log('WebSocket connection established again');
            console.log('Loading data from server');
            Object.keys(vezenyloStore).forEach((conductorName: string) => {
                vezenyloStore[conductorName].load();
            });
            buszrentEmployeeStore.load();
            busStore.load();
            resourceStore.load();
            eventStore.load();
            console.log('Data loaded from server');
        };
        socket.onmessage = function (event) {
            console.log('WebSocket message received');
            let jsonData = JSON.parse(event.data);
            if (jsonData.added.length > 0) {
                jsonData.added = jsonData.added.filter((record: any) => {
                    const id = record.id;
                    return eventStore.getById(id) === undefined;
                });
            }
            if (jsonData.updated.length > 0) {
                jsonData.updated = jsonData.updated.filter((record: any) => {
                    const id = record.id;
                    return eventStore.getById(id) !== undefined && eventStore.getById(id).getData('version') < record.version;
                });
            }
            if (jsonData.needUpdateResource) {
                resourceStore.load();
            }
            console.log('Applying changeset');
            eventStore.applyChangeset(jsonData);
        };
        socket.onclose = function (event) {
            console.log('WebSocket connection closed', event);
            setTimeout(connectWebSocket, 5000);
        };
        socket.onerror = function (error: Event) {
            const ws = error.currentTarget as WebSocket;
            console.error('WebSocket error: ', ws.url);
            showError(`Hálózati hiba történt, nem sikerült a websockethez csatlakozni`);
            socket.close();
        };
    }

    connectWebSocket();

    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + user.accessToken,
    };

    const config = {
        headers,
    };

    const onException = (event: any) => {
        if (event.exception && event.exceptionType === 'network') {
            showError('Hálózati hiba történt, kérjük ellenőrizze az internetkapcsolatot!');
            return;
        }

        const response = event.response;
        const body = event.json;

        if (event.exception && event.exceptionType === 'server') {
            showResponseError(response, body);
            return;
        }

        if (!body) {
            return;
        }

        if (event.action === 'create') {
            const model: EventModel = event.source.getById(event.body.data[0].id);
            if (model) event.source.remove(model);
        }
        if (event.action === 'update') {
            const model: EventModel = event.source.getById(event.body.data[0].id);
            model.revertChanges();
        }
        if (event.action === 'delete') {
            event.source.changes.removed.length && event.source.add(event.source.changes.removed);
        }
    };

    const onAfterRequest = (event: any) => {
        const response = event.response;
        const body = event.json;
        if (!response.ok && response.status === 401) {
            signOut();
            return;
        }
        calendar.current?.instance.refresh();
        if (!body || !body.success) {
            return;
        }
        if (event.action === 'create' || event.action === 'update') {
            const data = body.data[0];
            const resource = resourceStore.getById(data.resourceId);
            if (resource.getData('name') === 'Szerviz előjegyzés') {
                resourceStore.load();
            }
        }
        if (event.action === 'delete') {
            resourceStore.load();
        }
    };

    Object.keys(vezenyloStore).forEach((conductorName: string) => {
        vezenyloStore[conductorName].setConfig(config);
        vezenyloStore[conductorName].onException = onException;
        vezenyloStore[conductorName].onAfterRequest = onAfterRequest;
    });

    busStore.setConfig(config);
    busStore.onException = onException;

    buszrentEmployeeStore.setConfig(config);
    buszrentEmployeeStore.onException = onException;

    const resourceStore = new ResourceStore({
        httpMethods: {
            create: 'POST',
            read: 'GET',
            update: 'PUT',
            delete: 'DELETE',
        },
        useRestfulMethods: true,
        autoCommit: true,
        modelClass: ResourceModel,
        readUrl: '/api/resource',
        headers,
        autoLoad: true,
        sorters: [
            {
                field: 'position',
                ascending: true,
            },
        ],
        onAfterRequest,
        onException,
    });

    const createBusService = async (eventRecord: any) => {
        const payload = {
            event_id: eventRecord.data.id,
            licence_plate: eventRecord.data.name,
            comment: eventRecord.data.comment,
        };
        const defaultErrorMessage = 'Nem sikerült létrehozni a munkalapot';

        try {
            const response = await fetch("/api/event/bus_service", {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                showSuccess('A munkalap sikeresen létrejött');
                const event = eventStore.getById(eventRecord.data.id);
                event.set('isHandledInExternalService', true);
                return;
            }
            const body = await response.json();
            showError(body.userError || defaultErrorMessage);

        } catch (error: any) {
            showError(error?.response?.data?.userError || defaultErrorMessage);
        }
    };

    class BuszrentEventModel extends EventModel {
        static get fields() {
            return [
                { name: 'name', type: 'string' },
                { name: 'busId', type: 'integer' },
                { name: 'employeeId', type: 'string' },
                { name: 'availability', type: 'boolean' },
                { name: 'comment', type: 'string' },
                { name: 'version', type: 'integer' },
                { name: 'isHandledInExternalService', type: 'boolean' },
            ];
        }
    }

    const eventStore = new EventStore({
        httpMethods: {
            read: 'GET',
            create: 'POST',
            update: 'PUT',
            delete: 'DELETE',
        },
        writeAllFields: true,
        autoCommit: true,
        useRestfulMethods: true,
        modelClass: BuszrentEventModel,
        readUrl: '/api/event',
        createUrl: '/api/event',
        updateUrl: '/api/event',
        deleteUrl: '/api/event',
        headers,
        onBeforeRequest(event: any) {
            if (!(event.action === 'create' || event.action === 'update')) {
                return;
            }
            if (!event.body) {
                return;
            }
            const data = event.body.data[0];
            const localCommentStorage = JSON.parse(localStorage.getItem('comment') || '{}');
            if (event.action === 'create' && localCommentStorage['new']) {
                delete localCommentStorage['new'];
                localStorage.setItem('comment', JSON.stringify(localCommentStorage));
            }
            if (event.action === 'update' && localCommentStorage[data.id]) {
                delete localCommentStorage[data.id];
                localStorage.setItem('comment', JSON.stringify(localCommentStorage));
            }
            const resource = resourceStore.getById(data.resourceId);
            const entityType = resource.getData('entityType');
            const startDate = new Date(data.startDate);
            const endDate = new Date(data.endDate);
            const timeDifference = endDate.getTime() - startDate.getTime();
            if (timeDifference < 24 * 59 * 60 * 1000) {
                data.buszrentAllDay = false;
            }
            if (data.allDay) {
                data.buszrentAllDay = true;
            }
            if (data.buszrentAllDay) {
                data.startDate = new Date(startDate.setHours(0, 0, 0, 0)).toISOString();
                if (endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getSeconds() === 0) {
                    endDate.setDate(endDate.getDate() - 1);
                    endDate.setHours(23, 59, 59, 999);
                    data.endDate = endDate.toISOString();
                }
                data.buszrentAllDay = true;
            }
            if (data.busId != null && entityType === 'BUS') {
                const bus = busStore.getById(data.busId);
                data.name = bus.getData('licenseplate');
                return;
            }
            if (!(data.employeeId != null && entityType !== 'BUS')) {
                return;
            }

            if (entityType.toLowerCase().includes('buszrent_employee')) {
                const employee = buszrentEmployeeStore.getById(data.employeeId);
                data.name = employee.getData('name');
                return;
            }
            if (resource.getData('group') === null) {
                const employee = vezenyloStore[ConductorType.ALL].getById(data.employeeId);
                data.name = employee.getData('name');
                return;
            }
            const employee = vezenyloStore[resource.getData('group')].getById(data.employeeId);
            data.name = employee.getData('name');
        },
        onAfterRequest,
        onException,
    });

    const handleSelectResource = () => {
        if (!user) {
            return [];
        }
        const calendars = [ResourceID.SZABADSAG, ResourceID.SZABADSAGIGENY];

        if (user.hasRole(Role.ADMIN)) {
            return Object.values(ResourceID);
        }
        if (user.hasRole(Role.BR_MUSZAKI)) {
            calendars.push(ResourceID.SZERVIZ_KOZPONT, ResourceID.SZERVIZ_KULSOS, ResourceID.SZERVIZ_ELOJEGYZES, ResourceID.SZERVIZ_FELADATOK);
        }

        if (user.hasRole(Role.VEZENYLO)) {
            calendars.push(ResourceID.SZERVIZ_ELOJEGYZES);

            if (user.isInGroup('déli vezénylő')) {
                calendars.push(ResourceID.ELERHETO_SOFOR_DEL, ResourceID.FOGLALT_SOFOR_DEL);
            }
            if (user.isInGroup('északi vezénylő')) {
                calendars.push(ResourceID.ELERHETO_SOFOR_ESZAK, ResourceID.FOGLALT_SOFOR_ESZAK);
            }
            if (user.isInGroup('szegedi vezénylő')) {
                calendars.push(ResourceID.ELERHETO_SOFOR_SZEGED, ResourceID.FOGLALT_SOFOR_SZEGED);
            }
        }

        return calendars;
    };

    const handleDefaultSelectResource = () => {
        if (!user) {
            return [];
        }
        if (user.hasRole(Role.ADMIN)) {
            return ResourceID.SZERVIZ_KOZPONT;
        }
        if (user.hasRole(Role.BR_MUSZAKI)) {
            return ResourceID.SZERVIZ_KOZPONT;
        }

        if (user.hasRole(Role.VEZENYLO)) {
            if (user.isInGroup('déli vezénylő')) {
                return ResourceID.ELERHETO_SOFOR_DEL;
            }
            if (user.isInGroup('északi vezénylő')) {
                return ResourceID.ELERHETO_SOFOR_ESZAK;
            }
            if (user.isInGroup('szegedi vezénylő')) {
                return ResourceID.ELERHETO_SOFOR_SZEGED;
            }
        }
        return ResourceID.SZABADSAGIGENY;
    };

    const handleResourcePermission = (resource: Model) => {
        if (user?.hasRole(Role.ADMIN)) {
            return true;
        }
        const resourceGroup = resource.getData('group');
        const resourceRole = resource.getData('role');
        if (!resourceGroup && !resourceRole) {
            return true;
        }
        if (resourceRole) {
            const resourceRoles = resourceRole.split(',');
            for (const role of resourceRoles) {
                if (user?.hasRole(role as Role)) {
                    if (!resourceGroup) {
                        return true;
                    }
                    if (user?.isInGroup(resourceGroup)) {
                        return true;
                    }
                }
            }
            return false;
        }
        return !!(resourceGroup && user?.isInGroup(resourceGroup));
    };

    const handleBeforeEventEditShow = (event: any) => {
        const { eventRecord } = event;
        if (localStorage.getItem('comment')) {
            const localCommentStorage = JSON.parse(localStorage.getItem('comment') || '{}');
            const commentField = event.editor.items.find((item: any) => item.name === 'comment');
            const nameField = event.editor.items.find((item: any) => item.name === 'name');
            if (nameField.value && nameField.value !== 'Új esemény') {
                if (localCommentStorage[eventRecord.id]) {
                    commentField.value = localCommentStorage[eventRecord.id];
                }
            } else {
                commentField.value = localCommentStorage['new'];
            }
        }
        if (eventRecord.getData('buszrentAllDay')) {
            const startTimeField = event.editor.items.find((item: any) => item.ref === 'startTimeField');
            const endTimeField = event.editor.items.find((item: any) => item.ref === 'endTimeField');
            if (startTimeField) {
                startTimeField.value = '00:00';
                startTimeField.readOnly = true;
            }
            if (endTimeField) {
                endTimeField.value = '23:59:59';
                endTimeField.readOnly = true;
            }
        }
    };

    const handleDragEvent = (event: any) => {
        const { eventRecord } = event;
        if (!eventRecord || !eventRecord.getData('buszrentAllDay')) return;

        const startDate = new Date(eventRecord.getData('startDate'));
        const endDate = new Date(eventRecord.getData('endDate'));

        startDate.setHours(0, 0, 0, 0);
        eventRecord.set('startDate', startDate);
        if (endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getSeconds() === 0) {
            endDate.setDate(endDate.getDate() - 1);
            endDate.setHours(23, 59, 59, 999);
            eventRecord.set('endDate', endDate);
        }
    };

    const handleBeforeDragMove = (event: any) => {
        const { eventRecord } = event;
        if (!eventRecord || !eventRecord.getData('buszrentAllDay')) return;

        const startDate = new Date(eventRecord.getData('startDate'));
        const endDate = new Date(eventRecord.getData('endDate'));
        const timeDifference = endDate.getTime() - startDate.getTime();

        if (timeDifference < 24 * 60 * 60 * 1000) {
            eventRecord.set('allDay', true);
        }
    };

    const eventRenderer = ({ eventRecord }: any) => {
        return `
            <div><strong>${StringHelper.encodeHtml(eventRecord.name || '')}</strong></div>
            <div>${StringHelper.encodeHtml(eventRecord.comment || '')}</div>
          `;
    };

    return (
        <SecuredLayout>
            <Grid container sx={{ height: 'calc(100vh - 60px)' }}>
                <BryntumCalendar
                    ref={calendar}
                    crudManager={{
                        eventStore: eventStore,
                        resourceStore: resourceStore,
                    }}
                    mode='agenda'
                    modes={{
                        week: {
                            eventRenderer,
                        },
                        day: {
                            eventRenderer,
                        },
                        agenda: {
                            date: today,
                            startDate: today,
                            alwaysShowCurrentDate: true,
                            store: {
                                filters: [
                                    {
                                        property: 'date',
                                        operator: '>=',
                                        value: today.setHours(0, 0, 0, 0),
                                    },
                                ],
                            },
                            eventRenderer: ({ eventRecord }: any) => {
                                return [
                                    {
                                        className: 'b-event-name',
                                        html: `<strong>${StringHelper.encodeHtml(eventRecord.name)} </strong> ${
                                            eventRecord.comment ? '| Megjegyzés: ' + StringHelper.encodeHtml(eventRecord.comment) : ''
                                        }`,
                                    },
                                ];
                            },
                        },
                    }}
                    sidebar={{
                        items: {
                            resourceFilter: {
                                title: 'Naptárak',
                                store: {
                                    sorters: [
                                        {
                                            field: 'position',
                                            direction: 'ASC',
                                        },
                                    ],
                                },
                                selected: handleSelectResource(),
                                itemTpl: (resource: any) => {
                                    if (resource.getData('name') === 'Szerviz előjegyzés') {
                                        return resource.name + ' (' + resource.getData('countEvents') + ')';
                                    }
                                    if (resource.getData('name') === 'Szabadságigény') {
                                        return resource.name + ' (' + resource.getData('countEvents') + ')';
                                    }
                                    return resource.name;
                                },
                            },
                        },
                    }}
                    tbar={{
                        items: {
                            export: {
                                type: 'button',
                                weight: 200,
                                text: 'Szerviz feladatok exportálása',
                                icon: 'b-fa b-fa-file-excel',
                                onClick: () => {
                                    calendar.current?.instance.eventStore.filter((record: Model) => {
                                        const startDate = new Date().setHours(0, 0, 0, 0);
                                        const endDate = new Date().setHours(23, 59, 59, 999);
                                        if (record.getData('startDate').getTime() > endDate || record.getData('endDate').getTime() < startDate) {
                                            return false;
                                        }
                                        return record.getData('resourceId') === ResourceID.SZERVIZ_FELADATOK;
                                    });
                                    calendar.current?.instance.features.excelExporter.export({});
                                    calendar.current?.instance.eventStore.clearFilters();
                                },
                            },
                        },
                    }}
                    excelExporterFeature={{
                        filename: 'Szerviz feladatok',
                        dateFormat: 'YYYY-MM-DD HH:mm',
                        exporterConfig: {
                            resourceColumns: [{ text: 'Naptár', field: 'name', width: 200 }],
                            eventColumns: [
                                { text: 'Név', field: 'name', width: 200 },
                                { text: 'Kezdés', field: 'startDate', width: 200 },
                                { text: 'Vége', field: 'endDate', width: 200 },
                                { text: 'Megjegyzés', field: 'comment', width: 200 },
                            ],
                        },
                        xlsProvider: MyXlsProvider,
                    }}
                    eventTooltipFeature={{
                        showOn: 'hover',
                        renderer: ({ eventRecord }: any) => {
                            if (!eventRecord) return;
                            const durationHour = Math.ceil(DateHelper.as('hour', eventRecord.getData('duration'), eventRecord.getData('durationUnit')));
                            const durationMinute =
                                DateHelper.as('minute', eventRecord.getData('duration'), eventRecord.getData('durationUnit')) - durationHour * 60;
                            return `
                                <div>
                                    <div><strong>Kezdés:</strong> ${new Date(eventRecord.getData('startDate')).toLocaleString()}</div>
                                    <div><strong>Vége:</strong> ${new Date(eventRecord.getData('endDate')).toLocaleString()}</div>
                                    <div><strong>Időtartam:</strong> ${durationHour ? durationHour + ' óra ' : ''} 
                                    ${durationMinute > 0 ? durationMinute + ' perc' : ''}</div>
                                    <div><strong>Rendelkezésre állás:</strong> ${eventRecord.getData('availability') ? 'Igen' : 'Nem'}</div>
                                    <div><strong>Megjegyzés:</strong> ${eventRecord.getData('comment') ? eventRecord.getData('comment') : 'Nincs megjegyzés 😞'}</div>
                                </div>
                            `;
                        },
                    }}
                    eventMenuFeature={{
                        processItems({ eventRecord, items }) {
                            if (eventRecord.resourceId === ResourceID.SZERVIZ_ELOJEGYZES && !eventRecord.getData("isHandledInExternalService")) {
                                items.createServiceButton = {
                                    text: 'Munkalapnyitás',
                                    icon: 'b-fa b-fa-file-alt',
                                    weight: 200,
                                    onItem({ eventRecord }) {
                                        if (!eventRecord) return;
                                        if (eventRecord.getData('resourceId') !== ResourceID.SZERVIZ_ELOJEGYZES) return;
                                        createBusService(eventRecord);

                                    },
                                };
                            }
                        },
                        items: {
                            showCommentHistory: {
                                text: 'Korábbi megjegyzések megtekintése',
                                icon: 'b-fa b-fa-comment',
                                weight: 200,
                                onItem({ eventRecord }) {
                                    if (!eventRecord) return;
                                    new Popup({
                                        title: 'Korábbi megjegyzések',
                                        autoShow: true,
                                        closeAction: 'destroy',
                                        closable: true,
                                        modal: {
                                            closeOnMaskTap: true,
                                        },
                                        minWidth: 1000,
                                        minHeight: 500,
                                        items: {
                                            showGrid: {
                                                type: 'grid',
                                                features: {
                                                    cellTooltip: {
                                                        tooltipRenderer: ({
                                                                              record,
                                                                              column,
                                                                          }) => record.get(column.field),
                                                        hoverDelay: 200,
                                                    },
                                                },
                                                data: eventRecord.getData('comments').slice(0, -1),
                                                width: 950,
                                                height: 450,
                                                columns: [
                                                    {
                                                        field: 'CreatedBy',
                                                        text: 'Létrehozó',
                                                        readOnly: true,
                                                    },
                                                    {
                                                        text: 'Megjegyzés',
                                                        field: 'Comment',
                                                        readOnly: true,
                                                        width: 600,
                                                        fitMode: 'textContent',
                                                        flex: 2,
                                                    },
                                                    {
                                                        field: 'CreatedAt',
                                                        text: 'Létrehozás dátuma',
                                                        type: 'time',
                                                        readOnly: true,
                                                        width: 200,
                                                        renderer(renderData) {
                                                            return new Date(renderData.record.get(renderData.column.field)).toLocaleString();
                                                        },
                                                        tooltipRenderer: ({
                                                                              record,
                                                                              column,
                                                                          }) => new Date(record.get(column.field)).toLocaleString(),
                                                    },
                                                ],
                                            },
                                        },
                                    });
                                },
                            },
                        },
                    }}
                    eventEditFeature={{
                        editorConfig: {
                            modal: true,
                            width: 600,
                        },
                        items: {
                            resourceField: {
                                name: 'resourceId',
                                type: 'resourcecombo',
                                label: 'Naptár',
                                weight: 100,
                                showEventColor: true,
                                multiSelect: false,
                                store: {
                                    chainedFilterFn: handleResourcePermission,
                                },
                                listeners: {
                                    change: (event: any) => {
                                        let { source, value } = event;
                                        if (!value) {
                                            return;
                                        }
                                        if (source.owner.items[0].value === 'Új esemény') {
                                            value = handleDefaultSelectResource();
                                            source.owner.items[1].value = handleDefaultSelectResource();
                                        }
                                        const resource = resourceStore.getById(value);
                                        const entityType = resource.getData('entityType');
                                        const defaultAvailability = resource.getData('defaultAvailability');

                                        const loggedInUserEmployee = buszrentEmployeeStore.find((em: EmployeeModel) => em.get('name') === user?.name);

                                        source.owner.items.forEach((widget: any) => {
                                            if (widget.name === 'busId') {
                                                widget.hidden = entityType !== 'BUS';
                                                widget.required = entityType === 'BUS';
                                                return;
                                            }
                                            if (widget.name === 'employeeId') {
                                                if (value === ResourceID.SZABADSAGIGENY && !user.isInGroup('line-manager')) {
                                                    widget.hidden = false;
                                                    widget.readOnly = true;
                                                    if (loggedInUserEmployee) {
                                                        widget.value = loggedInUserEmployee;
                                                    }
                                                    return;
                                                }
                                                const group = resource.getData('group');
                                                widget.hidden = !(
                                                    entityType.toLowerCase().includes('bus_driver_employee') ||
                                                    entityType.toLowerCase().includes('buszrent_employee')
                                                );
                                                widget.required =
                                                    entityType.toLowerCase().includes('bus_driver_employee') ||
                                                    entityType.toLowerCase().includes('buszrent_employee');
                                                if (entityType.toLowerCase().includes('buszrent_employee')) {
                                                    widget.store = buszrentEmployeeStore;
                                                    return;
                                                }
                                                if (group === null) {
                                                    widget.store = vezenyloStore[ConductorType.ALL];
                                                    return;
                                                }
                                                widget.store = vezenyloStore[group];
                                            }
                                            if (widget.name === 'availability') {
                                                widget.hidden = defaultAvailability.Valid;
                                                return;
                                            }
                                            if (widget.name === 'name') {
                                                widget.hidden = entityType !== 'PERSONAL';
                                                widget.required = entityType === 'PERSONAL';
                                                return;
                                            }
                                        });
                                    },
                                },
                            },
                            busSelector: {
                                name: 'busId',
                                type: 'busSelector',
                                label: 'Buszok',
                                editable: false,
                                clearable: true,
                                weight: 100,
                            },
                            employeeSelector: {
                                name: 'employeeId',
                                type: 'EmployeeSelector',
                                label: 'Dolgozók',
                                editable: false,
                                clearable: true,
                                weight: 100,
                            },
                            availabilityField: {
                                name: 'availability',
                                type: 'slidetoggle',
                                label: 'Rendelkezésre állás',
                                weight: 100,
                            },
                            commentField: {
                                name: 'comment',
                                type: 'textareafield',
                                label: 'Megjegyzés',
                                weight: 200,
                                height: 150,
                                listeners: {
                                    keyUp: (event: any) => {
                                        const { source } = event;
                                        const localCommentStorage = JSON.parse(localStorage.getItem('comment') || '{}');
                                        const nameField = source.owner.items.find((item: any) => item.name === 'name');
                                        if (nameField.value && nameField.value !== 'Új esemény') {
                                            localCommentStorage['latestId'] = source.owner.eventEditFeature.editor.record.id;
                                            localCommentStorage[source.owner.eventEditFeature.editor.record.id] = source.value;
                                            localStorage.setItem('comment', JSON.stringify(localCommentStorage));
                                            return;
                                        }
                                        localCommentStorage['latestId'] = 'new';
                                        localCommentStorage['new'] = source.value;
                                        localStorage.setItem('comment', JSON.stringify(localCommentStorage));
                                    },
                                },
                            },
                            allDay: null,
                            buszrentAllDay: {
                                name: 'buszrentAllDay',
                                type: 'checkbox',
                                label: 'Egész nap',
                                weight: 200,
                                listeners: {
                                    change: (event: any) => {
                                        let { source, value } = event;
                                        source.owner.items.forEach((widget: any) => {
                                            if (widget.ref === 'startTimeField') {
                                                if (value) widget.value = '00:00';
                                                widget.readOnly = value;
                                                widget.editable = !value;
                                            }
                                            if (widget.ref === 'endTimeField') {
                                                if (value) widget.value = '23:59:59';
                                                widget.readOnly = value;
                                                widget.editable = !value;
                                            }
                                        });
                                    },
                                },
                            },
                        },
                    }}
                    onBeforeEventEdit={(event: any) => {
                        if (event.eventRecord.data.name === 'Új esemény') {
                            return true;
                        }
                        const resource = resourceStore.getById(event.eventRecord.data.resourceId);
                        return handleResourcePermission(resource);
                    }}
                    onAfterEventEdit={() => {
                        const localCommentStorage = JSON.parse(localStorage.getItem('comment') || '{}');
                        if (localCommentStorage['latestId']) {
                            const latestId = localCommentStorage['latestId'];
                            delete localCommentStorage['latestId'];
                            delete localCommentStorage[latestId];
                            localStorage.setItem('comment', JSON.stringify(localCommentStorage));
                        }
                    }}
                    onBeforeEventEditShow={handleBeforeEventEditShow}
                    onBeforeDragMove={handleBeforeDragMove}
                    onBeforeDragMoveEnd={handleDragEvent}
                    onBeforeDragResizeEnd={handleDragEvent}
                />
            </Grid>
        </SecuredLayout>
    );
}
